





$font-stack: $cnx-font-fallbacks;
$connections-regular: $cnx-family, $cnx-font-fallbacks;
$connections-condensed: $cnx-cond-family, $cnx-font-fallbacks;
$connections-italic-condensed: $cnx-italic-cond-family, $cnx-font-fallbacks;
$connections-medium: $cnx-medium-family, $cnx-font-fallbacks;
$connections-bold: $cnx-bold-family, $cnx-font-fallbacks;

[data-sparta-container] {
    .spa-header {
        &--1 {
          font: rem-calc(22px) $connections-regular !important;
          color: $bank-white !important;
        }

        &--1a {
            font: rem-calc(22px) $connections-regular !important;
        }

        &--2 {
            font: rem-calc(22px) $connections-medium !important;
            color: $bank-dark-blue !important;
            @media #{$medium-up} {
                font-size: rem-calc(24px) !important;
            }
        }

        &--2a {
            font: rem-calc(22px) $connections-regular !important;
            color: $bank-dark-blue !important;
            @media #{$medium-up} {
                font-size: rem-calc(24px) !important;
            }
        }

        &--3 {
            font: rem-calc(20px) $connections-regular !important;
            line-height: rem-calc(18) !important;
            color: $bank-dark-blue !important;
            @media #{$medium-up} {
                font: rem-calc(24px) !important;
                line-height: rem-calc(20) !important;
            }
        }

        &--4 {
            font: rem-calc(18px) $connections-regular !important;
            line-height: rem-calc(16) !important;
            color: $bank-dark-blue !important;
            @media #{$medium-up} {
                font: rem-calc(20px) !important;
                line-height: rem-calc(18) !important;
            }
        }

        &--5 {
            font: rem-calc(16px) $font-stack !important;
            color: $bank-white !important;
        }

        &--5a {
            font: rem-calc(16px) $font-stack !important;
        }
    }

    .spa-content {
        &--1 {
            font: rem-calc(18px) $connections-regular !important;
            color: $bank-brown-secondary !important;
            @media #{$medium-up} {
                font-size: rem-calc(20px) !important;
            }
        }

        &--1b {
            font: rem-calc(18px) $connections-regular !important;
            color: $bank-primary-red !important;
            @media #{$medium-up} {
                font-size: rem-calc(20px) !important;
            }
        }

        &--2 {
            font: rem-calc(18px) $font-stack !important;
            line-height: rem-calc(14) !important;
            color: $bank-brown-tertiary !important;
        }

        &--2a {
            font: rem-calc(16px) $font-stack !important;
            line-height: rem-calc(12) !important;
            color: $bank-brown-tertiary !important;
            @media #{$medium-up} {
                font: rem-calc(18px) !important;
                line-height: rem-calc(14) !important;
            }
        }

        &--2b {
            font: rem-calc(16px) $font-stack !important;
            line-height: rem-calc(12) !important;
            color: $bank-brown-tertiary !important;
        }

        &--3 {
            font: rem-calc(16px) $font-stack !important;
            line-height: rem-calc(12) !important;
            color: $bank-white !important;
        }

        &--4 {
            font: $spa-font-weight-bold rem-calc(16px) $font-stack !important;
            line-height: rem-calc(14) !important;
            color: $bank-mid-gray !important;
            @media #{$medium-up} {
                color: $bank-brown-tertiary !important;
            }
        }

        &--5 {
            font: $spa-font-weight-bold rem-calc(14px) $font-stack !important;
            line-height: rem-calc(14) !important;
            color: $bank-brown-tertiary !important;
        }

        &--5a {
            font: rem-calc(14px) $font-stack !important;
            line-height: rem-calc(14) !important;
            color: $bank-brown-tertiary !important;
        }

        &--6 {
            font: $spa-font-weight-bold rem-calc(16px) $font-stack !important;
            line-height: rem-calc(14) !important;
            color: $bank-brown-tertiary !important;
        }

        &--7 {
            font: rem-calc(16px) $connections-regular !important;
            color: $bank-brown-secondary !important;
            @media #{$medium-up} {
                font-size: rem-calc(18px) !important;
            }
        }

        &--8 {
            font: rem-calc(20px) $font-stack !important;
            line-height: rem-calc(16) !important;
            color: $bank-mid-gray !important;
        }

        &--8a {
            font: rem-calc(18px) $font-stack !important;
            color: $bank-mid-gray !important;
        }

        &--8b {
            font: rem-calc(14px) $font-stack !important;
            line-height: rem-calc(20) !important;
            color: $bank-brown-tertiary !important;
            @media #{$medium-up} {
                font-size: rem-calc(16px) !important;
            }
        }

        &--9 {
            font: rem-calc(14px) $font-stack !important;
            line-height: rem-calc(16) !important;
            color: $bank-brown-tertiary !important;
            @media #{$medium-up} {
                font-weight: $spa-font-weight-bold !important;
                font-size: rem-calc(20px) !important;
            }
        }
    }

    .spa-required {
        font: rem-calc(11px) $font-stack !important;
        color: $bank-black !important;
        @media #{$medium-up} {
            font-size: rem-calc(13px) !important;
        }
    }

    .spa-messaging {
        &--title {
            font: $spa-font-weight-bold rem-calc(12px) $font-stack !important;
            color: $bank-brown-tertiary !important;
            @include spa-center-vertically();
            @media #{$medium-up} {
                font-size: rem-calc(14px) !important;
            }
        }

        &--body {
            font: rem-calc(16px) $font-stack !important;
            line-height: rem-calc(12) !important;
            color: $bank-brown-tertiary !important;
            @media #{$medium-up} {
                font: rem-calc(18px) !important;
                line-height: rem-calc(14) !important;
            }
        }
    }

    .spa-link {
        font: rem-calc(14px) $font-stack !important;
        color: $bank-medium-blue !important;

        &--a {
            font: rem-calc(14px) $font-stack !important;
            color: $bank-medium-blue !important;
        }

        &--b {
            font: rem-calc(14px) $font-stack !important;

        }
    }

    .spa-footnote {
        font: rem-calc(13px) $font-stack !important;
        color: $bank-brown-secondary !important;
    }

    .spa-tagline {
      letter-spacing: .25em;
      line-height: 1.1;
      text-transform: uppercase;
      font: 1em $connections-condensed !important;
      -webkit-font-smoothing: auto;
      color: $black;

      &-inverse {
        & .spa-tagline {
          color: $white;
        }
      }

      &-campaign {
        position: relative;
        display: inline-block;
        padding-top: .6em;

        &::before {
          position: absolute;
          content: '';
          width: 21%;
          top: 0;
          border-bottom: 3px solid $bright-blue;
        }

        &::after {
          position: absolute;
          content: '';
          width: 74.75%;
          left: 23.5%;
          top: 0;
          border-top: 3px solid $bright-red;
        }
      }

      &-glass {
        position: relative;
        width:100%;
        height:100%;

        &-title {
          position: absolute;
          left:0;
          right:0;
          padding: 1em;
          text-align: center;
          background: rgba(255,255,255, .8);

          -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);

          &--top {
            top: 0;
          }

          &--bottom {
            bottom: 0;
          }

          & :last-child {
            margin-bottom: 0;
          }
        }
      }

      &-shadow {
        width:100%;
        height:100%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        background: linear-gradient(to bottom, transparent 0%,rgba(0,0,0,.5) 50%,rgba(0,0,0,.5) 50%,transparent 100%);

        &-title {
          width:100%;
          color: $white;

          & .spa-tagline {
            color: $white !important;
          }
        }
      }
    }
}
