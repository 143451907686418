






    @import '_foundation-input-defaults.scss';
    @import '_foundation-type-defaults.scss';

    [data-sparta-container] {
        box-sizing: border-box;
        font: $spa-font;
        color: $bank-dark-gray;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: #fff;
        color: #222;
        cursor: auto;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        margin: 0;
        padding: 0;
        position: relative;

        &.no-scroll {
            overflow: hidden;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        p {
            font: $spa-font;
        }


        a {
            color: $spa-link-color;
            text-decoration: none;

            &:visited {
              color: $spa-link-color-visited;
            }

            &:hover {
              color: $spa-link-color-hover;
              text-decoration: underline;
            }

            &:focus {
              color: $spa-link-color-focus;
              outline: medium none;
              text-decoration: underline;
            }
        }

        code {
            word-wrap: break-word;
        }
        .nowrap code, .nowrap samp {
            white-space: nowrap;
        }

        table {
            background: none;
        }

        img {
            border: 0 none;
        }

        sup, sub {
            line-height: 0;
        }

        h1 sup a[data-footnote],
        h2 sup a[data-footnote],
        h3 sup a[data-footnote],
        h4 sup a[data-footnote] {
            font-size: 75%;
        }

        form {
            margin: 0;
            padding: 0;
        }

        input {
            margin: 0 !important;
        }

        fieldset {
            margin: 0;
            padding: 0;
            width: auto;
            overflow: hidden;
            border: 0;
        }

        ul, ol, dl {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
        }

        ul.gray-sq-bullet li {
            list-style-position: inside;
            list-style-type: square;
        }

        a:visited {
          color: $spa-link-color-visited;
        }

        picture {
          width: 100%;

          > source,
          > img {
            display: block;
            width: 100%;
          }
        }
    }
