  [data-sparta-container] {

    button, input[type="button"], input[type="reset"], input[type="submit"] {
      -webkit-appearance: button;
      cursor: pointer;
    }

    button,.button {
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 0;
      border-style: solid;
      border-width: 0;
      cursor: pointer;
      font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
      font-weight: normal;
      line-height: normal;
      margin: 0 0 1.25rem;
      position: relative;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      padding: 1rem 2rem 1.0625rem 2rem;
      font-size: 1rem;
      background-color: #008cba;
      border-color: #007095;
      color: #fff;
      -webkit-transition: background-color 300ms ease-out;
      transition: background-color 300ms ease-out
    }
    label {
      line-height: 1.5;
      cursor: pointer;
    }

    textarea {
      height: auto;
      min-height: 50px;
      appearance: none;
      border-radius: 0;
      background-color: #FFFFFF;
      border-style: solid;
      border-width: 1px;
      border-color: #cccccc;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.75);
      display: block;
      font-family: inherit;
      font-size: 14px;
      height: 27.75px;
      margin: 0 0 12px 0;
      padding: 6px;
      width: 100%;
      box-sizing: border-box;
      transition: border-color 0.15s linear, background 0.15s linear;
      &:focus {
          background: #fafafa;
          border-color: #999999;
          outline: none;
      }
      &:disabled {
          background-color: #DDDDDD;
          cursor: default;
      }
    }

    select {
      width: 100%;
    }

    input {
      &[type="text"],
      &[type="password"],
      &[type="date"],
      &[type="datetime"],
      &[type="datetime-local"],
      &[type="month"],
      &[type="week"],
      &[type="email"],
      &[type="number"],
      &[type="search"],
      &[type="tel"],
      &[type="time"],
      &[type="url"],
      &[type="color"] {
        appearance: none;
        border-radius: 0;
        background-color: #FFFFFF;
        border-style: solid;
        border-width: 1px;
        border-color: #cccccc;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.75);
        display: block;
        font-family: inherit;
        font-size: 14px;
        height: 27.75px;
        margin: 0 0 12px 0;
        padding: 6px;
        width: 100%;
        box-sizing: border-box;
        transition: border-color 0.15s linear, background 0.15s linear;
      }

      &[type="text"]:focus,
      &[type="password"]:focus,
      &[type="date"]:focus,
      &[type="datetime"]:focus,
      &[type="datetime-local"]:focus,
      &[type="month"]:focus,
      &[type="week"]:focus,
      &[type="email"]:focus,
      &[type="number"]:focus,
      &[type="search"]:focus,
      &[type="tel"]:focus,
      &[type="time"]:focus,
      &[type="url"]:focus,
      &[type="color"]:focus {
          background: #fafafa;
          border-color: #999999;
          outline: none;
      }

      &[type="text"]:disabled,
      &[type="password"]:disabled,
      &[type="date"]:disabled,
      &[type="datetime"]:disabled,
      &[type="datetime-local"]:disabled,
      &[type="month"]:disabled,
      &[type="week"]:disabled,
      &[type="email"]:disabled,
      &[type="number"]:disabled,
      &[type="search"]:disabled,
      &[type="tel"]:disabled,
      &[type="time"]:disabled,
      &[type="url"]:disabled,
      &[type="color"]:disabled {
          background-color: #DDDDDD;
          cursor: default;
      }
    }

    select {
      appearance: none !important;
      background-color: #FAFAFA;
      border-radius: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
      background-position: 100% center;
      background-repeat: no-repeat;
      border-style: solid;
      border-width: 1px;
      border-color: #cccccc;
      color: rgba(0, 0, 0, 0.75);
      font-family: inherit;
      font-size: 14px;
      line-height: normal;
      padding: 8px;
      border-radius: 0;
      height: 37px;
    }

    select::-ms-expand {
      display: none;
    }

    select.radius {
      border-radius: 3px;
    }

    select:hover {
      background-color: #f3f3f3;
      border-color: #999999;
    }

    select:disabled {
      background-color: #DDDDDD;
      cursor: default;
    }

    select[multiple] {
      height: auto;
    }


    input[type="file"],
    input[type="checkbox"],
    input[type="radio"],
    select {
      margin: 0 0 16px 0;
    }

    input[type="checkbox"] + label,
    input[type="radio"] + label {
      display: inline-block;
      margin-left: 8px;
      margin-right: 16px;
      margin-bottom: 0;
      vertical-align: baseline;
    }


    input[type="file"] {
      width: 100%;
    }



    fieldset {
      border: 1px solid #DDDDDD;
      margin: 18px 0;
      padding: 20px;

      legend {
        background: #FFFFFF;
        font-weight: bold;
        margin-left: -3px;
        margin: 0;
        padding: 0 3px;
      }
    }
  }
