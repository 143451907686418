

$btn-base: 'spa-btn';


$btn-font-family: $cnx-family, $cnx-font-fallbacks;
$btn-font-weight: normal;
$btn-text-align: center;
$btn-line-height: 1.0;
$btn-large--line-height: 25px;
$btn-small--font-size: 16px;
$btn-medium--font-size: 20px;
$btn-large--font-size: 24px;
$btn-engagement--font-size: 32px;


$btn-small--padding-vertical: 7px;
$btn-small--padding-horizontal: 15px;
$btn-medium--padding-vertical: 10px;
$btn-medium--padding-horizontal: 20px;
$btn-large--padding-vertical: 10px;
$btn-large--padding-horizontal: 25px;
$btn-engagement--padding-vertical: 14px;
$btn-engagement--padding-horizontal: 30px;
$btn-small--padding: $btn-small--padding-vertical $btn-small--padding-horizontal;
$btn-medium--padding: $btn-medium--padding-vertical $btn-medium--padding-horizontal;
$btn-large--padding: $btn-large--padding-vertical $btn-large--padding-horizontal;
$btn-engagement--padding: $btn-engagement--padding-vertical $btn-engagement--padding-horizontal;


$btn-bordered--border-width: 2px;


$btn-disabled--text-color: $brand-gray;
$btn-disabled--bg-color: $brand-pale-gray-light;
$btn-disabled--link--bg-color: transparent;


$me-contrast-button-bg-color: $bank-primary-red;
$me-contrast-button-text-color: $bank-white;
$me-contrast-button-hover-bg-color: $bank-regal-red;
$me-contrast-button-hover-text-color: $bank-white;


$btn-skin--primary: (
  text-color: $bank-white,
  bg-color: $brand-royal-blue,
  hover-text-color: $bank-white,
  hover-bg-color: $brand-dark-blue,
  box-shadow: 0 0 0 3px $bank-dark-blue  inset#{","} 0 0 0 4px $bank-white inset
);

$btn-skin--secondary: (
  text-color: $bank-medium-blue,
  bg-color: $brand-pale-gray-light,
  border-color: $color-royal-one,
  hover-text-color: $bank-dark-blue,
  hover-bg-color: $brand-pale-gray,
  hover-border-color: $brand-dark-blue,
  border-width: $btn-bordered--border-width
);

$btn-skin--reverse: (
  text-color: $bank-primary-blue,
  bg-color: $bank-white,
  hover-text-color: $bank-dark-blue,
  hover-bg-color: $bank-white
);

$btn-skin--white-border: (
  text-color: $bank-white,
  bg-color: transparent,
  border-color: $bank-white,
  hover-text-color: $brand-royal-blue,
  hover-bg-color: $bank-white,
  border-width: $btn-bordered--border-width
);

$btn-skin--red-border: (
  text-color: $brand-bright-red,
  bg-color: transparent,
  border-color: $brand-bright-red,
  hover-text-color: $bank-white,
  hover-bg-color: $brand-bright-red,
  border-width: $btn-bordered--border-width
);

$btn-skin--blue-border: (
  text-color: $brand-bright-blue,
  bg-color: transparent,
  border-color: $brand-bright-blue,
  hover-text-color: $bank-white,
  hover-bg-color: $brand-bright-blue,
  border-width: $btn-bordered--border-width
);

$btn-skin--link: (
  text-color: $bank-medium-blue,
  bg-color: transparent,
  hover-text-color: $bank-dark-blue,
  hover-bg-color: transparent
);

$btn-skin--disabled: (
  text-color: $btn-disabled--text-color,
  bg-color: $btn-disabled--bg-color,
  hover-text-color: $btn-disabled--text-color,
  hover-bg-color: $btn-disabled--bg-color
);

$btn-skin--reverse-disabled: (
  text-color: $btn-disabled--text-color,
  bg-color: $bank-white,
  hover-text-color: $btn-disabled--text-color,
  hover-bg-color: $bank-white
);
