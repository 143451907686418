



@mixin spa-btn--skin($skin) {
  color: map-get($skin, 'text-color');
  background-color: map-get($skin, 'bg-color');

  @if map-has-key($skin, 'border-color') {
    border: map-get($skin, 'border-width') solid map-get($skin, 'border-color');
  }

  &:visited {
    color: map-get($skin, 'text-color');
  }

  &:hover,
  &:focus {
    color: map-get($skin, 'hover-text-color');
    background: map-get($skin, 'hover-bg-color');

    @if map-has-key($skin, 'box-shadow') {
      box-shadow: map-get($skin, 'box-shadow');
    }

    @if map-has-key($skin, 'border-color') {
      border-color: map-get($skin, 'hover-border-color');
    }
  }
}




@mixin spa-btn--small {
  font-size: $btn-small--font-size;
  padding: $btn-small--padding;
}

@mixin spa-btn--medium {
  font-size: $btn-medium--font-size;
  padding: $btn-medium--padding;
}

@mixin spa-btn--large {
  font-size: $btn-large--font-size;
  line-height: $btn-large--line-height;
  padding: $btn-large--padding;
}

@mixin spa-btn--engagement {
  font-size: $btn-engagement--font-size;
  padding: $btn-engagement--padding;
}

@mixin spa-btn--block {
  display: block;
  width: 100%;
}

$spa-btn--padding-map: (
  small: $btn-small--padding-vertical,
  medium: $btn-medium--padding-vertical,
  large: $btn-large--padding-vertical,
  engagement: $btn-engagement--padding-vertical
);


@mixin spa-btn--compensate-for-border($border-width) {
  @each $modifier in (small, medium, large, engagement) {
    &.#{$btn-base}--#{$modifier} {
      padding-top: (map-get($spa-btn--padding-map, '#{$modifier}') - $border-width);
      padding-bottom: (map-get($spa-btn--padding-map, '#{$modifier}') - $border-width);
    }
  }
}











@mixin build-responsive-button-classes-for-breakpoint($breakpoint-name) {
  @each $modifier in (small, medium, large, engagement, block) {
    .#{$btn-base}--#{$modifier}-at-#{$breakpoint-name} {

      @if $modifier == small {
        @include spa-btn--small;
      } @else if $modifier == medium {
        @include spa-btn--medium;
      } @else if $modifier == large {
        @include spa-btn--large;
      } @else if $modifier == engagement {
        @include spa-btn--engagement;
      } @else if $modifier == block {
        @include spa-btn--block;
      }
    }
  }
}







@mixin build-responsive-button-classes--media-query-version {
  [data-sparta-container] {
    @each $breakpoint-name, $breakpoint-query in $breakpoint-list {
      @media #{$breakpoint-query} {
        @include build-responsive-button-classes-for-breakpoint($breakpoint-name);
      }
    }
  }
}





@mixin build-responsive-button-classes--class-scoped-version {
  @each $breakpoint-name, $breakpoint-query in $breakpoint-list {
    .#{$breakpoint-name} {
      @include build-responsive-button-classes-for-breakpoint($breakpoint-name);
    }
  }
}
