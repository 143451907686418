

@import '_config.scss';
@import '_mixins.scss';

[data-sparta-container] {

  .spa-btn {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    text-align: $btn-text-align;
    text-decoration: none;
    vertical-align: middle;
    position: relative;
    margin: 0;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
    user-select: none;
    transition: $spa-standard-transition;


    &:hover {
      text-decoration: none;
    }




    &--primary {
      @include spa-btn--skin($btn-skin--primary);
    }

    &--secondary {
      @include spa-btn--skin($btn-skin--secondary);
    }

    &--reverse {
      @include spa-btn--skin($btn-skin--reverse);
    }

    &--white-border {
      @include spa-btn--skin($btn-skin--white-border);
    }

    &--red-border {
      @include spa-btn--skin($btn-skin--red-border);
    }

    &--blue-border {
      @include spa-btn--skin($btn-skin--blue-border);
    }

    &--link {
      @include spa-btn--skin($btn-skin--link);


      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }



    &[disabled],
    &--disabled {
      @include spa-btn--skin($btn-skin--disabled);

      pointer-events: none;


      &.spa-btn--reverse {
        @include spa-btn--skin($btn-skin--reverse-disabled);
      }


      &.spa-btn--link {
        background-color: $btn-disabled--link--bg-color;
      }
    }




    &--small {
      @include spa-btn--small;
    }

    &--medium {
      @include spa-btn--medium;
    }

    &--large {
      @include spa-btn--large;
    }

    &--engagement {
      @include spa-btn--engagement;
    }

    &--block {
      @include spa-btn--block;
    }





    &--white-border {
      @include spa-btn--compensate-for-border($btn-bordered--border-width);
    }

    &--red-border {
      @include spa-btn--compensate-for-border($btn-bordered--border-width);
    }

    &--blue-border {
      @include spa-btn--compensate-for-border($btn-bordered--border-width);
    }

    &--link {
      padding: 0; 
    }


    &--contrast {
      background-color: $me-contrast-button-bg-color;
      color: $me-contrast-button-text-color;

      &:visited {
        color: $me-contrast-button-text-color;
      }

      &:hover,
      &:focus {
        background: $me-contrast-button-hover-bg-color;
        color: $me-contrast-button-hover-text-color;
      }
    }
  }







  .spa-btn--expand-on-small {
    width: 100%;
    display: block;
  }

  .spa-btn--expand-on-medium {
    width: 100%;
    display: block;
  }

  .medium-up & {
    .spa-btn--expand-on-small {
      width: auto;
      display: inline-block;
    }
  }

  .large-up & {
    .spa-btn--expand-on-medium {
      width: auto;
      display: inline-block;
    }
  }




  .spa-btn-group {
    .spa-btn {
      margin-right: 10px;
      margin-bottom: 15px;
    }

    .spa-btn--small {
      margin-right: 5px;
    }
  }

  .spa-input-btn-group {
    .spa-input-btn-column {
      .spa-btn--medium {
        margin-top: 21px;
        margin-right: 10px;
      }
    }
  }




  .svg-button {
    &:focus {
      outline: 1px dotted $bank-brown-primary;
    }

    &--dark-bg {
      &:focus {
        outline-color: $bank-white;
      }
    }
  }
}


@include build-responsive-button-classes--media-query-version;  
@include build-responsive-button-classes--class-scoped-version; 
