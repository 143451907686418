


  [data-sparta-container] {
    width: 100%;
    min-height: 100%;
    background: #f9f7f4;
    margin: 0 auto;

    .full-width-row {
      &.row,
      &>.row {
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    .row {
      width: 100%;
      max-width: $max-width !important;
      margin-left: auto;
      margin-right: auto;

      &:before,
      &:after {
        content: " ";
        display: table;
      }

      &:after {
        clear: both;
      }

      > .small {
        @include grid($columns);

        @include offset($columns);

        @include push($columns);

        @include pull($columns);

        @include reset-order();
      }

      .small {
        @include block-grid($columns);
      }
    }

    [class*="block-grid-"] {
      display: block;
      padding: 0;
      margin: 0 #{$small-gutter * -1};

      > li {
        display: block;
        float: left;
        height: auto;
        padding: 0 $small-gutter $small-gutters;
        list-style: none;
      }
    }

    .columns,
    .column {
      position: relative;
      float: left;
      width: 100%;

      & + .columns:last-child,
      & + .column:last-child {
        float: right;
      }

      & + .columns.end,
      & + .column.end {
        float: left;
      }

      &.small-centered,
      &.centered {
        float: none;
        margin-left: auto;
        margin-right: auto;

        &:last-child {
          float: none;
        }
      }

      &.small-uncentered,
      &.uncentered {
        float: left;
        margin-left: 0;
        margin-right: 0;

        &:last-child {
          float: right;
        }
      }
    }

    .small-up & {
      .row {
        margin-left: auto;
        margin-right: auto;
        padding-left: $small-gutter;
        padding-right: $small-gutter;

        &.collapse,
        &.small-collapse,
        &.spa-collapse-small {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          padding-left: 0;
          padding-right: 0;

          > .columns,
          > .column {
            padding-left: 0;
            padding-right: 0;
          }
        }

        &.small-uncollapse,
        &.spa-uncollapse-small {
          width: 100%;
          padding-left: 0;
          padding-right: 0;

          @include uncollapse-columns('small', $small-gutter);
        }

        @include uncollapse-columns('small', $small-gutter);
      }
    }

    .medium-up & {
      .row {
        margin-left: auto;
        margin-right: auto;
        padding-left: $medium-gutter;
        padding-right: $medium-gutter;

        &.medium-collapse,
        &.spa-collapse-medium {
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;

          > .columns,
          > .column {
            padding-left: 0;
            padding-right: 0;
          }
        }

        &.medium-uncollapse,
        &.spa-uncollapse-medium {
          padding-left: $medium-gutter;
          padding-right: $medium-gutter;

          @include uncollapse-columns('medium', $medium-gutter);
        }

        > .medium {
          @include grid($columns);

          @include offset($columns);

          @include push($columns);

          @include pull($columns);

          @include reset-order();
        }

        .medium {
          @include block-grid($columns);
        }

        @include uncollapse-columns('medium', $medium-gutter);
      }

      [class*="block-grid-"] {
        display: block;
        padding: 0;
        margin: 0 #{$medium-gutter * -1};

        > li {
          display: block;
          float: left;
          height: auto;
          padding: 0 $medium-gutter $medium-gutters;
          list-style: none;
        }
      }
    }

    .large-up & {
      .row {
        margin-left: auto;
        margin-right: auto;
        padding-left: $large-gutter;
        padding-right: $large-gutter;

        &.large-collapse,
        &.spa-collapse-large {
          margin-left: auto;
          margin-right: auto;
          padding-left: 0;
          padding-right: 0;

          > .columns,
          > .column {
            padding-left: 0;
            padding-right: 0;
          }
        }

        &.large-uncollapse,
        &.spa-uncollapse-large {
          padding-left: $large-gutter;
          padding-right: $large-gutter;

          @include uncollapse-columns('large', $large-gutter);
        }

        > .large {
          @include grid($columns);

          @include offset($columns);

          @include push($columns);

          @include pull($columns);

          @include reset-order();
        }

        .large {
          @include block-grid($columns);
        }

        @include uncollapse-columns('large', $large-gutter);
      }

      [class*="block-grid-"] {
        display: block;
        padding: 0;
        margin: 0 #{$large-gutter * -1};

        > li {
          display: block;
          float: left;
          height: auto;
          padding: 0 $large-gutter $large-gutters;
          list-style: none;
        }
      }
    }

    .largeCentered & {
      .row {
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
        padding-left: $large-gutter;
        padding-right: $large-gutter;
      }
    }
  }

  .sparta-media-query-utility-loading :not([data-sparta-widget]),
  .spa-ui-layer.spa-ui-modal {
    .row {
      margin-left: auto;
      margin-right: auto;
      padding-left: $small-gutter;
      padding-right: $small-gutter;

      &.collapse,
      &.small-collapse,
      &.spa-collapse-small {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;

        > .columns,
        > .column {
          padding-left: 0;
          padding-right: 0;
        }
      }

      &.small-uncollapse,
      &.spa-uncollapse-small {
        margin-left: auto;
        margin-right: auto;
        padding-left: $small-gutter;
        padding-right: $small-gutter;
      }

      > .columns,
      > .column {
        padding-left: $small-gutter;
        padding-right: $small-gutter;

        &.small-centered,
        &.centered {
          float: none;
          margin-left: auto;
          margin-right: auto;

          &:last-child {
            float: none;
          }
        }

        &.small-uncentered,
        &.uncentered {
          float: left;
          margin-left: 0;
          margin-right: 0;

          &:last-child {
            float: right;
          }
        }
      }
    }

    @media #{$medium-up} {
      .row {
        margin-left: auto;
        margin-right: auto;
        padding-left: $medium-gutter;
        padding-right: $medium-gutter;

        &.medium-collapse,
        &.spa-collapse-medium {
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;

          > .columns,
          > .column {
            padding-left: 0;
            padding-right: 0;
          }
        }

        &.medium-uncollapse,
        &.spa-uncollapse-medium {
          padding-left: $medium-gutter;
          padding-right: $medium-gutter;
        }

        > .medium {
          @include grid($columns);

          @include offset($columns);

          @include push($columns);

          @include pull($columns);

          @include reset-order();
        }

        .medium {
          @include block-grid($columns);
        }

        > .columns,
        > .column {
          padding-left: $medium-gutter;
          padding-right: $medium-gutter;

          &.medium-centered {
            float: none;
            margin-left: auto;
            margin-right: auto;

            &:last-child {
              float: none;
            }
          }

          &.medium-uncentered {
            float: left;
            margin-left: 0;
            margin-right: 0;

            &:last-child {
              float: right;
            }
          }
        }
      }

      [class*="block-grid-"] {
        display: block;
        padding: 0;
        margin: 0 #{$medium-gutter * -1};

        > li {
          display: block;
          float: left;
          height: auto;
          padding: 0 $medium-gutter $medium-gutters;
          list-style: none;
        }
      }
    }

    @media #{$large-up} {
      .row {
        margin-left: auto;
        margin-right: auto;
        padding-left: $large-gutter;
        padding-right: $large-gutter;

        &.collapse,
        &.large-collapse,
        &.spa-collapse-large {
          margin-left: auto;
          margin-right: auto;
          padding-left: 0;
          padding-right: 0;

          > .columns,
          > .column {
            padding-left: 0;
            padding-right: 0;
          }
        }

        &.large-uncollapse,
        &.spa-uncollapse-large {
          margin-left: auto;
          margin-right: auto;
          padding-left: $large-gutter;
          padding-right: $large-gutter;
        }

        > .large {
          @include grid($columns);

          @include offset($columns);

          @include push($columns);

          @include pull($columns);

          @include reset-order();
        }

        .large {
          @include block-grid($columns);
        }

        > .columns,
        > .column {
          padding-left: $large-gutter;
          padding-right: $large-gutter;

          &.large-centered {
            float: none;
            margin-left: auto;
            margin-right: auto;

            &:last-child {
              float: none;
            }
          }

          &.large-uncentered {
            float: left;
            margin-left: 0;
            margin-right: 0;

            &:last-child {
              float: right;
            }
          }
        }
      }

      [class*="block-grid-"] {
        display: block;
        padding: 0;
        margin: 0 #{$large-gutter * -1};

        > li {
          display: block;
          float: left;
          height: auto;
          padding: 0 $large-gutter $large-gutters;
          list-style: none;
        }
      }
    }

    @media only screen and (min-width: #{$max-width + $large-row-margin}) {
      .row {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: $large-gutter;
        padding-right: $large-gutter;

        &.large-collapse,
        &.spa-collapse-large {
          margin-left: auto;
          margin-right: auto;

          > .columns,
          > .column {
            padding-left: 0;
            padding-right: 0;
          }
        }

        &.large-uncollapse,
        &.spa-uncollapse-large {
          margin-left: auto;
          margin-right: auto;
          padding-left: $large-gutter;
          padding-right: $large-gutter;
        }
      }
    }
  }
